/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import { connect } from "react-redux"

import { getFont, getBannerFont } from "../helpers"

const { REACT_APP_DOMAIN_ZONE: domainZone, REACT_APP_BRAND: brand } = process.env

const country = domainZone.includes("-") ? domainZone.substring(0, domainZone.lastIndexOf("-")) : domainZone

const isIndexed = domainZone.includes("-") && domainZone.split("-")[1] === "eu"

const gtmEnabledZones = ["nz", "br", "cl", "pe"]

const Head = ({ translations, homePage }) => {
  const shouldUseGtm = domainZone && gtmEnabledZones.includes(country.toLowerCase())

  const mainFont = getFont(brand)
  const bannerFont = getBannerFont(brand)

  const { metaTitle, metaDescription } = homePage

  return (
    <Helmet
      htmlAttributes={{
        class: `theme theme-${brand}`
      }}
    >
      <title>{metaTitle || translations?.metaTitle}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      {isIndexed && <link rel="canonical" href="https://www.leovegas.eu" />}
      {!isIndexed && <meta name="robots" content="noindex, nofollow" />}
      {!isIndexed && <meta name="googlebot" content="noindex, nofollow" />}
      {!isIndexed && <meta name="AdsBot-Google" content="noindex, nofollow" />}
      {!isIndexed && <meta name="google-site-verification" content="eETJvGQNvFMj8ByGiqfcpX7G4Z1niDE2Me_JRnASnlk" />}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <script>
        {shouldUseGtm &&
          `window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments);}
            if (localStorage.getItem('consentMode') === null){
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'personalization_storage': 'denied',
                'functionality_storage': 'denied',
                'security_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied'
              })
            } else {
              gtag ('consent', 'default', JSON.parse(localStorage.getItem('consentMode')))
            }`}
      </script>
      <link href={mainFont} rel="stylesheet" />
      <link href={bannerFont} rel="stylesheet" />
      <link rel="shortcut icon" href={require(`../assets/${brand}/favicon.png`).default} type="image/x-icon" />
    </Helmet>
  )
}

Head.defaultProps = {}

Head.propTypes = {
  translations: PropTypes.objectOf(PropTypes.any).isRequired,
  homePage: PropTypes.objectOf(PropTypes.any).isRequired
}

const mapStateToProps = ({ head, translations, homePage }) => ({
  isGtmAvailable: head.isGtmAvailable,
  translations,
  homePage
})

export default connect(mapStateToProps)(Head)
